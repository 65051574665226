/**
 * 这里是封装的 api hook
 * 原始的 api 接口在 services/apis
 */
import { useArchivedProject } from './use-archived-project';
import { useBulkAddToProject } from './use-bulk-add-to-project';
import { useCreateFTLQuote } from './use-create-ftl-quote';
import { useCreateFTLShipment } from './use-create-ftl-shipment';
import { useCreateProject } from './use-create-project';
import { useCreateQuote } from './use-create-quote';
import { useCreateShipment } from './use-create-shipment';
import { useCreateWarehouseWithCheck } from './use-create-warehouse-with-check';
import { useDeleteProject } from './use-delete-project';
import { useEditProject } from './use-edit-project';
import { useFavoriteProject } from './use-favorite-project';
import { useFTLPullQuoteRates } from './use-ftl-pull-quote-rates';
import { useGetAgentShipmentList } from './use-get-agent-shipment-list';
import { useGetAgentUsers } from './use-get-agent-users';
import { useGetCityListByZipcode } from './use-get-city-list-by-zipcode';
import { useGetContainer } from './use-get-container';
import { useGetFTLParams } from './use-get-ftl-params';
import { useGetFTLQuote } from './use-get-ftl-quote';
import { useGetFTLQuoteList } from './use-get-ftl-quote-list';
import { useGetFTLShipment } from './use-get-ftl-shipment';
import { useGetFTLShipmentList } from './use-get-ftl-shipment-list';
import { useGetHolidays } from './use-get-holidays';
import { useGetIndustryNews } from './use-get-industry-news';
import { useGetLTLAutofill } from './use-get-ltl-autofill';
import { useGetOrder } from './use-get-order';
import { useGetProject } from './use-get-project';
import { useGetProjectCalendarProgress } from './use-get-project-calendar-progress';
import { useGetProjectContainers } from './use-get-project-containers';
import { useGetProjectContainersCalendar } from './use-get-project-containers-calendar';
import { useGetProjectContainersMap } from './use-get-project-containers-map';
import { useGetProjectDeliveryCountStatistics } from './use-get-project-delivery-count-statistics';
import { useGetProjectExceptions } from './use-get-project-exceptions';
import { useGetProjectMBLs } from './use-get-project-mbls';
import { useGetProjectMonthlyStatistics } from './use-get-project-monthly-statistics';
import { useGetProjectMyFocus } from './use-get-project-myfocus';
import { useGetProjectMyFocusMap } from './use-get-project-myfocus-map';
import { useGetProjectMyFocusTotal } from './use-get-project-myfocus-total';
import { useGetProjectsPickupDeliveryLFD } from './use-get-project-pickup-delivery-lfd';
import { useGetProjectProgress } from './use-get-project-progress';
import { useGetProjectProgressStatistics } from './use-get-project-progress-statistics';
import { useGetProjects } from './use-get-projects';
import { useGetProjectsExceptions } from './use-get-projects-exceptions';
import { useGetProjectsSummary } from './use-get-projects-summary';
import { useGetQuote } from './use-get-quote';
import { useGetQuoteList } from './use-get-quote-list';
import { useGetShipment } from './use-get-shipment';
import { useGetShipmentList } from './use-get-shipment-list';
import { useMultiOrdersCreateProject } from './use-multi-orders-create-project';
import { usePullQuoteRates } from './use-pull-quote-rates';
import { useSearchCity } from './use-search-city';
import { useSearchOwnMBLNumbers } from './use-search-own-mbl-numbers';
import { useSearchProjects } from './use-search-projects';
import { useSearchWarehouse } from './use-search-warehouse';
import { useSearchWarehouses } from './use-search-warehouses';
import { useUnfavoriteProject } from './use-unfavorite-project';
import { useUpdateFTLQuote } from './use-update-ftl-quote';
import { useUpdateQuote } from './use-update-quote';
import { useUpdateShipment } from './use-update-shipment';
import { useUpdateWarehouse } from './use-update-warehouse';

export const apiHooks = {
	useArchivedProject,
	useBulkAddToProject,
	useCreateProject,
	useCreateQuote,
	useCreateShipment,
	useCreateWarehouseWithCheck,
	useDeleteProject,
	useEditProject,
	useFavoriteProject,
	useGetCityListByZipcode,
	useGetContainer,
	useGetLTLAutofill,
	useGetOrder,
	useGetProject,
	useGetProjectContainers,
	useGetProjectContainersCalendar,
	useGetProjectCalendarProgress,
	useGetProjectContainersMap,
	useGetProjectDeliveryCountStatistics,
	useGetProjectExceptions,
	useGetProjectMBLs,
	useGetProjectMonthlyStatistics,
	useGetProjectMyFocus,
	useGetProjectMyFocusMap,
	useGetProjectMyFocusTotal,
	useGetProjectsPickupDeliveryLFD,
	useGetProjectProgress,
	useGetProjectProgressStatistics,
	useGetProjects,
	useGetProjectsExceptions,
	useGetProjectsSummary,
	useGetQuote,
	useGetQuoteList,
	useGetShipment,
	useGetShipmentList,
	useMultiOrdersCreateProject,
	usePullQuoteRates,
	useSearchCity,
	useSearchOwnMBLNumbers,
	useSearchProjects,
	useSearchWarehouses,
	useSearchWarehouse,
	useUnfavoriteProject,
	useGetIndustryNews,
	useUpdateQuote,
	useUpdateShipment,
	useUpdateWarehouse,
	useGetHolidays,
	useGetAgentShipmentList,
	useGetAgentUsers,
	useGetFTLParams,
	useCreateFTLQuote,
	useGetFTLQuoteList,
	useCreateFTLShipment,
	useFTLPullQuoteRates,
	useGetFTLQuote,
	useGetFTLShipmentList,
	useGetFTLShipment,
	useUpdateFTLQuote,
};
