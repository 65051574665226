import { useTranslation } from 'react-i18next';
import { FTLShipment } from '@/interfaces/ftl-shipment.interface';
import { useShipmentOrderContext } from '@/services/contexts';
import { Item } from '../item';

export const ShipmentItemList = () => {
	const { t } = useTranslation();

	const shipmentOrderContext = useShipmentOrderContext();

	const data = shipmentOrderContext.shipmentOrder as FTLShipment;

	return (
		<>
			<div className='flex flex-col gap-[24px]'>
				<div className='h4-b text-[#333333]'>{t(`Item`)}</div>

				<div className='grid grid-cols-2 grid-rows-[repeat(4,auto)] gap-x-[160px] gap-y-[16px]'>
					<Item title={t(`Commodity Name`)} content={data.commodity_name ?? ''} />
					<div></div>
					<Item
						title={t(`Number of units`)}
						content={`${data.total_units ?? ''} ${data.package_type ?? ''}`}
					/>
					<Item
						title={t(`Total weight`)}
						content={data.total_weight ? `${data.total_weight} lbs` : ''}
					/>
				</div>
			</div>
		</>
	);
};
