import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ContainerDetail } from '@/interfaces/container-detail.interface';
import '@/styles/no-border-table.less';
import clsx from 'clsx';
import { t } from 'i18next';
import _ from 'lodash';
import { ArrElement } from '@/utils/array-element.type';
import BillChargeExplanation from './bill-charge-explanation';

type Invoice = ArrElement<ContainerDetail['invoices']>;

type Charge = ArrElement<Invoice['charges']>;

interface BillProps {
	invoice: Invoice;
	className?: string;
}

export default ({ invoice, className }: BillProps) => {
	const totalAmout = _.sum(invoice.charges?.map((charge) => charge.rate * charge.qty));

	const columns: ColumnsType<Charge> = [
		{
			dataIndex: 'name',
			title: t('Description'),
		},
		{
			dataIndex: 'rate',
			title: t('Rate'),
			align: 'left',
			// render: (rate) => <div className='text-right'>${rate}</div>,
			render: (rate) => <>${(+rate)?.toFixed(2) || ''}</>,
		},
		{
			dataIndex: 'qty',
			title: t('Qty'),
			align: 'left',
			render: (qty) => <>{qty}</>,
			// render: (qty) => <div className='text-right'>{qty}</div>,
		},
		{
			key: 'amount',
			title: t('Amount'),
			render: (_, record) => (
				// <div className='body-4-b text-right'>${record.rate * record.qty}</div>
				<div className='body-4-b'>${(record.rate * record.qty).toFixed(2)}</div>
			),
		},
		{
			key: 'explanation',
			title: t('Explanation'),
			render: (_, record) => <BillChargeExplanation orderInvoiceId={invoice.id} charge={record} />,
		},
	];

	return (
		<div className={clsx(className)}>
			<Table
				className='no-border-table'
				rowClassName={'leading-[.25rem]'}
				columns={columns}
				dataSource={invoice.charges}
				pagination={false}
			/>

			<div className='body-3-b flex justify-end'>
				<span className='mr-[83px] text-grey-01'>{t('Total Amount')}</span>
				<div>
					<div>${totalAmout.toFixed(2)}</div>
					{invoice.is_invoice_confirmed && (
						<div className='body-1-b text-primary-dark-02'>
							{t('Due by ')}
							{invoice.due_at}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
