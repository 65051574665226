import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, Divider, Form, FormInstance, Input, message } from 'antd';
import * as _ from 'lodash';
import Calendar24pxIcon from '@/icons/calendar-24px-icon';
import CircleBlueFillIcon from '@/icons/circle-blue-fill';
import DestinationIcon from '@/icons/destination-icon';
import { QuoteBusinessStatus } from '@/interfaces/quote.interface';
import { useFTLContext } from '@/services/contexts';
import { kgToLb } from '@/services/contexts/ltl/ltl.context';
import { userContext } from '@/services/contexts/user';
import { useMemoizedFn } from 'ahooks';
import dayjs from 'dayjs';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { QuoteId } from '@/components/quote-id';
import { FormItem } from '@/pages/ltl-search-rate/form-item';
import { formItemWrapper } from '@/pages/ltl-search-rate/form-item-wrapper';
import { apiHooks } from '@/hooks/apis';
import { extractNumber } from '@/utils/extract-number';
import { formatMMDDYYYYDate } from '@/utils/format-MMDDYYYY-date';
import { ItemForm } from './item-form';
import { ShipmentDetailItem } from './shipment-detail-item';
import { SideMap } from './side-map';
import { TimeWindow } from './time-window';
import { useHandlePullQuote } from './use-handle-pull-quote';

const FinalizeShipmentDetailsForm = observer(({ form }: { form: FormInstance }) => {
	const { t } = useTranslation();

	const ftlContext = useFTLContext();

	const [pickupAddressForm] = Form.useForm();

	const [destinationAddressForm] = Form.useForm();

	const [pickupTimeWindowForm] = Form.useForm();

	const [destinationTimeWindowForm] = Form.useForm();

	const isQuoteDisabled = ftlContext.quote.business_status === QuoteBusinessStatus.EXPIRED;

	const { runAsync: createFTLShipment, loading: createFTLShipmentLoading } =
		apiHooks.useCreateFTLShipment();

	const { quoteRateCompleted, handleUpdateFTLQuote } = useHandlePullQuote();

	const { data: holidays, loading: holidaysLoading } = apiHooks.useGetHolidays();

	useEffect(() => {
		form.setFieldsValue({
			...ftlContext.shipmentFormValues,
		});
	}, [ftlContext.shipmentFormValues]);

	useEffect(() => {
		pickupAddressForm.setFieldsValue({
			...ftlContext.pickupAddress,
		});
	}, [ftlContext.pickupAddress]);

	useEffect(() => {
		destinationAddressForm.setFieldsValue({
			...ftlContext.destinationAddress,
		});
	}, [ftlContext.destinationAddress]);

	useEffect(() => {
		pickupTimeWindowForm.setFieldsValue({
			...ftlContext.pickupTimeWindow,
		});
	}, [ftlContext.pickupTimeWindow]);

	useEffect(() => {
		destinationTimeWindowForm.setFieldsValue({
			...ftlContext.destinationTimeWindow,
		});
	}, [ftlContext.destinationTimeWindow]);

	const handleCreateShipment = useMemoizedFn(async () => {
		if (!userContext.hasEnoughBalance(+ftlContext.rate.rate)) {
			message.error(t('Insufficient wallet balance'));
			return;
		}

		if (
			![QuoteBusinessStatus.CONFIRMED, QuoteBusinessStatus.SUBMITED].includes(
				ftlContext.quote.business_status,
			)
		) {
			message.error(
				t(`This Quote is expired or booked, please use Search Rate page to get the new Rate.`),
			);
			return;
		}

		await Promise.all([
			form.validateFields(),
			pickupAddressForm.validateFields(),
			pickupTimeWindowForm.validateFields(),
			destinationAddressForm.validateFields(),
			destinationTimeWindowForm.validateFields(),
		]);

		const data = {
			pickup_zipcode: ftlContext.pickupAddress?.zipcode,
			pickup_company_name: ftlContext.pickupAddress?.name,
			pickup_contact_name: ftlContext.pickupAddress?.contacts?.[0]?.name,
			pickup_contact_email: ftlContext.pickupAddress?.contacts?.[0]?.email,
			pickup_contact_phone: extractNumber(ftlContext.pickupAddress?.contacts?.[0]?.phone ?? ''),
			pickup_contact_phone_ext: ftlContext.pickupAddress?.contacts?.[0]?.phone_ext,
			pickup_address1: ftlContext.pickupAddress?.address,
			pickup_address2: ftlContext.pickupAddress?.address2,
			pickup_city_id: ftlContext.pickupAddress?.city?.id,
			pickup_special_request: ftlContext.pickupAddress?.special_request,

			destination_zipcode: ftlContext.destinationAddress?.zipcode,
			destination_company_name: ftlContext.destinationAddress?.name,
			destination_contact_name: ftlContext.destinationAddress?.contacts?.[0]?.name,
			destination_contact_email: ftlContext.destinationAddress?.contacts?.[0]?.email,
			destination_contact_phone: extractNumber(
				ftlContext.destinationAddress?.contacts?.[0]?.phone ?? '',
			),
			destination_contact_phone_ext: ftlContext.destinationAddress?.contacts?.[0]?.phone_ext,
			destination_address1: ftlContext.destinationAddress?.address,
			destination_address2: ftlContext.destinationAddress?.address2,
			destination_city_id: ftlContext.destinationAddress?.city?.id,
			destination_special_request: ftlContext.destinationAddress?.special_request,

			commodity_name: ftlContext.shipmentFormValues.item_description,
			total_units: ftlContext.shipmentFormValues.item_units,
			package_type: ftlContext.shipmentFormValues.item_package_type,
			total_weight:
				ftlContext.shipmentFormValues.item_weight_unit === 'lbs'
					? ftlContext.shipmentFormValues.item_weight
					: Math.ceil(kgToLb(Number(ftlContext.shipmentFormValues.item_weight) ?? 0)),

			pickup_time_type: ftlContext.pickupTimeWindow.value,
			pickup_open_time: dayjs(ftlContext.pickupTimeWindow.start).format('HH:mm'),
			pickup_close_time: dayjs(ftlContext.pickupTimeWindow.end).format('HH:mm'),
			pickup_specific_time: dayjs(ftlContext.pickupTimeWindow.specific).format('HH:mm'),
			destination_time_type: ftlContext.destinationTimeWindow.value,
			destination_open_time: dayjs(ftlContext.destinationTimeWindow.start).format('HH:mm'),
			destination_close_time: dayjs(ftlContext.destinationTimeWindow.end).format('HH:mm'),
			destination_specific_time: dayjs(ftlContext.destinationTimeWindow.specific).format('HH:mm'),

			estimated_pickup_date: ftlContext.pickupDate.format('YYYY-MM-DD'),
			customer_reference_number: ftlContext.shipmentFormValues.customer_reference_number,
			pickup_number: ftlContext.shipmentFormValues.pickup_number,
			dropoff_number: ftlContext.shipmentFormValues.dropoff_number,

			quote_id: ftlContext.quote.id,
			rate_id: ftlContext.rate.id,
		};

		console.log(`createFTLShipment`, toJS(data));

		const res = await createFTLShipment({ data });

		ftlContext.shipment = res.data.data;
		ftlContext.step = 3;
	});

	return (
		<>
			<QuoteId quoteId={ftlContext.quote?.uid} />
			{/* title */}
			<div className='h3-b text-[#333333]'>{t(`Finalize shipment details`)}</div>

			{/* content */}
			<div className='flex justify-between'>
				{/* form */}
				<div className='w-[710px] flex flex-col'>
					<div className='pl-[12px] flex flex-col gap-[24px]'>
						{/* title */}
						<div>
							<Divider />
							<div className='h4-b text-[#333333]'>{t(`Reference numbers & instructions`)}</div>
						</div>
						<div className='flex flex-row gap-[12px]'>
							<div className='flex gap-[12px]'>
								<FormItem titleProps={{ content: t('Customer Reference Number') }}>
									<Form.Item name='customer_reference_number'>
										<Input
											disabled={isQuoteDisabled}
											maxLength={255}
											style={formItemWrapper.style}
											className={`${formItemWrapper.className} w-[222px] h-[42px]`}
										/>
									</Form.Item>
								</FormItem>
							</div>
							<FormItem titleProps={{ content: t('Pickup Number') }}>
								<Form.Item name='pickup_number'>
									<Input
										disabled={isQuoteDisabled}
										maxLength={255}
										style={formItemWrapper.style}
										className={`${formItemWrapper.className} w-[222px] h-[42px]`}
									/>
								</Form.Item>
							</FormItem>
							<FormItem titleProps={{ content: t('Droppff Number') }}>
								<Form.Item name='dropoff_number'>
									<Input
										disabled={isQuoteDisabled}
										maxLength={255}
										style={formItemWrapper.style}
										className={`${formItemWrapper.className} w-[222px] h-[42px]`}
									/>
								</Form.Item>
							</FormItem>
						</div>
					</div>

					<Gap height='24px' />

					{/* title */}
					<div className='pl-[12px]'>
						<Divider />
						<div className='h4-b text-[#333333]'>{t(`Shipment details`)}</div>
					</div>

					<Gap height='16px' />

					<Form
						form={pickupAddressForm}
						validateMessages={{
							required: t(`Required`),
						}}
						onValuesChange={(changedValues, allValues) => {
							_.merge(ftlContext.pickupAddress, changedValues);
						}}
					>
						<ShipmentDetailItem
							type='pickup'
							titleIcon={<CircleBlueFillIcon />}
							borderColor='#C8E3FF'
							form={pickupAddressForm}
							disabled={isQuoteDisabled}
							extra={
								<div className='flex flex-col gap-[24px]'>
									<div className='flex items-end gap-[12px]'>
										{ftlContext.quote?.business_status === QuoteBusinessStatus.SUBMITED ? (
											<div
												style={formItemWrapper.style}
												className={`${formItemWrapper.className} pl-[4px] w-[222px] h-[42px] flex justify-center items-center bg-white rounded-[4px]`}
											>
												<Calendar24pxIcon />
												{/* <DateRangeOneCalendarPicker /> */}
												<DatePicker
													suffixIcon={null}
													value={ftlContext.pickupDate}
													placeholder={t(`Pickup Date`)}
													variant='borderless'
													className='w-full body-3-m text-black'
													disabledDate={(current) => {
														if (holidaysLoading) return false;
														return (
															current < dayjs().startOf('day') ||
															holidays?.includes(dayjs(current).format('YYYY-MM-DD'))
														);
													}}
													onChange={(date) => {
														ftlContext.pickupDate = date;
														handleUpdateFTLQuote();
													}}
												/>
											</div>
										) : (
											<FormItem titleProps={{ content: t('Pickup Date'), required: true }}>
												<Input
													disabled={
														ftlContext.quote?.business_status !== QuoteBusinessStatus.SUBMITED
													}
													value={formatMMDDYYYYDate(ftlContext.pickupDate)}
													style={formItemWrapper.style}
													className={`${formItemWrapper.className} w-[222px] h-[42px]`}
												/>
											</FormItem>
										)}

										{ftlContext.rate?.is_est_pickup_date_invalid && !isQuoteDisabled && (
											<div className='foot-note-1-r text-additional-warning'>
												{t(
													`The estimated delivery date falls on a weekend or holiday, it is recommended to adjust the pick-up date to avoid delayed delivery or additional charges.`,
												)}
											</div>
										)}
									</div>

									<FormItem titleProps={{ content: t('Pickup Time') }}>
										<Form
											form={pickupTimeWindowForm}
											validateMessages={{
												required: `Required`,
											}}
											onValuesChange={(changedValues, allValues) => {
												_.merge(ftlContext.pickupTimeWindow, changedValues);
											}}
										>
											<TimeWindow
												disabled={isQuoteDisabled}
												value={ftlContext.pickupTimeWindow.value}
												start={ftlContext.pickupTimeWindow.start}
												end={ftlContext.pickupTimeWindow.end}
												specific={ftlContext.pickupTimeWindow.specific}
											/>
										</Form>
									</FormItem>
								</div>
							}
						/>
					</Form>

					<Gap height='24px' />

					<Form
						form={destinationAddressForm}
						validateMessages={{
							required: t(`Required`),
						}}
						onValuesChange={(changedValues, allValues) => {
							_.merge(ftlContext.destinationAddress, changedValues);
						}}
					>
						<ShipmentDetailItem
							type='destination'
							titleIcon={<DestinationIcon />}
							borderColor='#FBBAB8'
							form={destinationAddressForm}
							disabled={isQuoteDisabled}
							extra={
								<div className='flex flex-col gap-[24px]'>
									<div className='flex gap-[12px]'>
										<FormItem titleProps={{ content: t('Delivery Date'), required: true }}>
											<Input
												disabled
												value={formatMMDDYYYYDate(ftlContext.rate?.estimated_delivery_date)}
												style={formItemWrapper.style}
												className={`${formItemWrapper.className} w-[222px] h-[42px]`}
											/>
										</FormItem>
									</div>

									<FormItem titleProps={{ content: t('Delivery time') }}>
										<Form
											form={destinationTimeWindowForm}
											validateMessages={{
												required: `Required`,
											}}
											onValuesChange={(changedValues, allValues) => {
												_.merge(ftlContext.destinationTimeWindow, changedValues);
											}}
										>
											<TimeWindow
												disabled={isQuoteDisabled}
												value={ftlContext.destinationTimeWindow.value}
												start={ftlContext.destinationTimeWindow.start}
												end={ftlContext.destinationTimeWindow.end}
												specific={ftlContext.destinationTimeWindow.specific}
											/>
										</Form>
									</FormItem>
								</div>
							}
						/>
					</Form>

					{/* Item */}
					<ItemForm disabled={isQuoteDisabled} />
				</div>

				<div className='relative box-border grow-0 overflow-hidden w-[382px] h-fit border-[1px] border-solid border-grey-05'>
					<SideMap
						disableButton={!ftlContext.rate || isQuoteDisabled}
						loading={!quoteRateCompleted}
						createLoading={createFTLShipmentLoading}
						onFinish={() => handleCreateShipment()}
					/>
				</div>
			</div>
		</>
	);
});

export const FinalizeShipmentDetails = observer(() => {
	const [form] = Form.useForm();

	const ftlContext = useFTLContext();

	return (
		<Form
			form={form}
			validateMessages={{
				required: `Required`,
			}}
			className='box-border m-auto p-[48px_6px] w-full h-full flex flex-col overflow-x-hidden step-form ltl-step-three'
			onValuesChange={(changedValues, allValues) => {
				_.merge(ftlContext.shipmentFormValues, changedValues);
			}}
		>
			<FinalizeShipmentDetailsForm form={form} />
		</Form>
	);
});
