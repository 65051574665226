import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Pagination, Select } from 'antd';
import * as _ from 'lodash';
import Search16pxIcon from '@/icons/search-16px-icon';
import { FTLShipment } from '@/interfaces/ftl-shipment.interface';
import { businessStatusMap } from '@/interfaces/shipment.interface';
import { CalendarOutlined, LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import { useMemoizedFn, useMount, useSetState } from 'ahooks';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { Table, TableProps } from '@/components/table';
import { apiHooks } from '@/hooks/apis';
import { StatusLabel } from './status-label';
import useFtlOrdersParams, { FTLOrdersQuery } from './use-ftl-orders-params';

export default observer(({ mode }: { mode?: string }) => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const { parsedSearchParams, changeSearchParams } = useFtlOrdersParams();

	const [queriesWithoutPagination, setQueriesWithoutPagination] = useSetState<
		Pick<FTLOrdersQuery, 'business_status' | 'last_days' | 'query' | 'sort_by' | 'sort_value'>
	>({
		business_status: parsedSearchParams.business_status ?? [],
		last_days: parsedSearchParams.last_days ?? '30',
		query: parsedSearchParams.query ?? '',
		sort_by: parsedSearchParams.sort_by ?? '',
		sort_value: parsedSearchParams.sort_value ?? 'desc',
	});

	const {
		runAsync: getFTLShipmentList,
		data: FTLShipmentList,
		loading: FTLShipmentListLoading,
		pagination,
	} = mode == 'agent'
		? apiHooks.useGetAgentShipmentList(
				{
					refreshDeps: [queriesWithoutPagination],
					refreshDepsAction: () => {
						getFTLShipmentList(
							{ current: 1, pageSize },
							{
								params: {
									...queriesWithoutPagination,
									business_status: (queriesWithoutPagination.business_status ?? [])
										.flatMap((i) => (i === '40' ? ['40', '60'] : [i]))
										.join(','),
									last_days:
										queriesWithoutPagination.last_days == 'all'
											? ''
											: queriesWithoutPagination.last_days,
								},
							},
						);
					},
				},
				'ftl',
		  )
		: apiHooks.useGetFTLShipmentList({
				refreshDeps: [queriesWithoutPagination],
				refreshDepsAction: () => {
					getFTLShipmentList(
						{ current: 1, pageSize },
						{
							params: {
								...queriesWithoutPagination,
								business_status: (queriesWithoutPagination.business_status ?? [])
									.flatMap((i) => (i === '40' ? ['40', '60'] : [i]))
									.join(','),
								last_days:
									queriesWithoutPagination.last_days == 'all'
										? ''
										: queriesWithoutPagination.last_days,
							},
						},
					);
				},
		  });

	const { current, total, pageSize, onChange: changePagination } = pagination;

	const columns: TableProps<FTLShipment>['columns'] = [
		{
			title: t(`Order #`),
			dataIndex: 'uid',
			CellRender: ({ value, record, style, className, onRowClick }) => (
				<div style={style} className={className} onClick={() => onRowClick(record)}>
					{value}
				</div>
			),
		},
		{
			key: t('agent'),
			dataIndex: ['user', 'name'],
			title: t('User'),
		},
		{
			title: t(`Reference #`),
			dataIndex: 'customer_reference_number',
			CellRender: ({ value, record, style, className, onRowClick }) => (
				<div style={style} className={className} onClick={() => onRowClick(record)}>
					{value ?? ''}
				</div>
			),
		},
		{
			title: t(`Status`),
			dataIndex: 'business_status',
			CellRender: ({ value, record, style, className, onRowClick }) => (
				<div style={style} className={className} onClick={() => onRowClick(record)}>
					<StatusLabel status={value} text={businessStatusMap[value]} />
				</div>
			),
		},
		{
			title: t(`Pickup Time`),
			dataIndex: 'actual_pickup_date',
			CellRender: ({ value, record, style, className, onRowClick }) => (
				<div style={style} className={className} onClick={() => onRowClick(record)}>
					<div className='flex flex-col'>
						<div>
							{!!record.actual_pickup_date || !!record.estimated_pickup_date
								? dayjs(record.actual_pickup_date ?? record.estimated_pickup_date).format(
										'YYYY-MM-DD',
								  )
								: ''}
						</div>
						{/* <div className='text-grey-01'>
							<TimeWindowRender
								time_type={record.pickup_time_type}
								open_time={record.pickup_open_time}
								close_time={record.pickup_close_time}
								specific_time={record.pickup_specific_time}
							/>
						</div> */}
					</div>
				</div>
			),
		},
		{
			title: t(`Pickup City`),
			dataIndex: 'pickup_zipcode',
			CellRender: ({ value, record, style, className, onRowClick }) => (
				<div style={style} className={className} onClick={() => onRowClick(record)}>
					<div className='flex flex-col'>
						<div>{record.pickup_city.name}</div>
						<div className='text-grey-01'>{`${record.pickup_city.state} - ${record.pickup_zipcode}`}</div>
					</div>
				</div>
			),
		},
		{
			title: t(`Delivery Time`),
			dataIndex: 'actual_delivery_date',
			CellRender: ({ value, record, style, className, onRowClick }) => (
				<div style={style} className={className} onClick={() => onRowClick(record)}>
					<div className='flex flex-col'>
						<div>
							{!!record.actual_delivery_date || !!record.estimated_delivery_date
								? dayjs(record.actual_delivery_date ?? record.estimated_delivery_date).format(
										'YYYY-MM-DD',
								  )
								: ''}
						</div>
						{/* <div className='text-grey-01'>
							<TimeWindowRender
								time_type={record.destination_time_type}
								open_time={record.destination_open_time}
								close_time={record.destination_close_time}
								specific_time={record.destination_specific_time}
							/>
						</div> */}
					</div>
				</div>
			),
		},
		{
			title: t(`Destination`),
			dataIndex: 'destination_zipcode',
			CellRender: ({ value, record, style, className, onRowClick }) => (
				<div style={style} className={className} onClick={() => onRowClick(record)}>
					<div className='flex flex-col'>
						<div>{record.destination_city.name}</div>
						<div className='text-grey-01'>{`${record.destination_city.state} - ${record.destination_zipcode}`}</div>
					</div>
				</div>
			),
		},
		{
			title: t(`Actions`),
			dataIndex: '',
			type: 'extra',
			CellRender: ({ value, record, style, className, onRowClick }) => (
				<div style={style} className={className} onClick={() => onRowClick(record)}>
					<Button type='link' onClick={() => navigate(`/ftl-orders/${record.uid}`)}>
						{t(`View`)}
					</Button>
				</div>
			),
		},
	];

	const _columns = useMemo(() => {
		return columns.filter((column) => {
			return !(mode != 'agent' && column.key === 'agent');
		});
	}, [columns, mode]);

	const handleChange = useMemoizedFn((newQueries: FTLOrdersQuery) => {
		console.log(`[handleChange] newQueries`, newQueries);

		if (Number.isInteger(newQueries.page) && Number.isInteger(newQueries.per_page)) {
			changePagination(newQueries.page, newQueries.per_page);
		} else if (
			Object.keys(newQueries).some((k) =>
				['sort_by', 'sort_value', 'query', 'business_status', 'last_days'].includes(k),
			)
		) {
			setQueriesWithoutPagination(newQueries);
		}

		changeSearchParams(newQueries);
	});

	const handleChangePagination = useMemoizedFn((page: number, pageSize: number) => {
		handleChange({
			page,
			per_page: pageSize,
		});
	});

	useMount(async () => {
		await getFTLShipmentList(
			{ pageSize: parsedSearchParams.per_page ?? 20, current: parsedSearchParams.page ?? 1 },
			{
				params: {
					...queriesWithoutPagination,
					business_status: (queriesWithoutPagination.business_status ?? []).join(','),
					last_days:
						queriesWithoutPagination.last_days == 'all' ? '' : queriesWithoutPagination.last_days,
				},
			},
		);

		changePagination(parsedSearchParams.page ?? 1, parsedSearchParams.per_page ?? 20);
	});

	return (
		<div className='box-border h-screen w-full p-[48px] flex flex-col bg-white overflow-hidden'>
			{/* header */}
			<div className='grow-0 shrink-0 w-full h-[44px] flex items-center gap-[8px]'>
				<div className='h3-b text-black'>
					{mode == 'agent' ? t(`FTL Orders`) : t(`My FTL Orders`)}
				</div>
				{FTLShipmentListLoading && <LoadingOutlined />}
			</div>

			<Gap height='36px' />

			{/* filters */}
			<div className='grow-0 shrink-0 w-full h-[42px] flex items-center gap-[12px]'>
				<Input
					value={queriesWithoutPagination.query}
					prefix={<Search16pxIcon />}
					placeholder={t(`Search Zipcode, Order #, Reference #`)}
					className='box-border h-[42px] w-[360px] border-[1px] border-solid border-grey-04'
					onChange={(e) => handleChange({ query: e.target.value })}
				/>

				<div className=' box-border p-[0_12px] h-[42px] w-[194px] border-[1px] border-solid border-grey-04 bg-white rounded-[4px] flex justify-center items-center'>
					<CalendarOutlined />
					<Select
						value={queriesWithoutPagination.last_days}
						suffixIcon={null}
						showSearch
						filterOption={false}
						variant='borderless'
						options={[
							{ label: t(`Last 30 days`), value: '30' },
							{ label: t(`Last 60 days`), value: '60' },
							{ label: t(`Last 90 days`), value: '90' },
							{ label: 'all', value: 'all' },
						]}
						className='w-full'
						onChange={(value) => handleChange({ last_days: value })}
					/>
				</div>

				<div className=' box-border p-[0_12px] h-[42px] min-w-[194px] border-[1px] border-solid border-grey-04 bg-white rounded-[4px] flex justify-center items-center'>
					<div></div>
					<Select
						value={queriesWithoutPagination.business_status}
						suffixIcon={null}
						mode='multiple'
						showSearch
						filterOption={false}
						variant='borderless'
						options={Object.entries(businessStatusMap)
							.filter(([value, label]) => value !== '60')
							.map(([value, label]) => ({ label, value: `${value}` }))}
						placeholder={t(`Status`)}
						allowClear
						className='w-full'
						onChange={(value) => handleChange({ business_status: value })}
					/>
				</div>

				<Button
					type='link'
					icon={<SyncOutlined />}
					onClick={() =>
						handleChange({
							query: '',
							last_days: '30',
							business_status: undefined,
						})
					}
				>
					{<span className='body-3-m text-primary-regular'>{t(`Reset`)}</span>}
				</Button>
			</div>

			<Gap height='24px' />

			{/* table */}
			<div className=' grow shrink w-full flex flex-col gap-[12px] overflow-hidden'>
				<div className='grow shrink border-[1px] border-solid border-[#E7E7E7] rounded-[8px] overflow-hidden'>
					<Table
						columns={_columns}
						dataSource={FTLShipmentList}
						className='max-h-full h-fit w-full  overflow-y-scroll'
						commonHeaderClass='sticky top-0 z-50  h-[48px] flex items-center body-4-r text-grey-01 bg-[#F5F7FA]'
						commonBodyClass='h-[70px] flex items-center body-4-r text-black'
						commonCellClass='px-[16px]'
						commonCellStyle={{
							borderBottom: '1px solid #E7E7E7',
						}}
					/>
				</div>
				<div className='grow-0 shrink-0 flex justify-center'>
					<Pagination
						showSizeChanger
						current={current}
						total={total}
						pageSize={pageSize}
						onChange={handleChangePagination}
					/>
				</div>
			</div>
		</div>
	);
});
