import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Divider } from 'antd';
import { FTLShipment } from '@/interfaces/ftl-shipment.interface';
import { downloadFtlDefaultBOLFile } from '@/services/apis';
import { useShipmentOrderContext } from '@/services/contexts';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Gap } from '@/components/gap';
import { MapBox } from '@/pages/search-rate/components/mapbox';
import { formatMMDDYYYYDate } from '@/utils/format-MMDDYYYY-date';
import { Item } from '../item';

export const FTLSideShipmentInfo = () => {
	const { t } = useTranslation();

	const [searchParams] = useSearchParams();

	const openBol =
		searchParams.get('openBol') && Number(searchParams.get('openBol')) == 1 ? true : false;

	const shipmentOrderContext = useShipmentOrderContext();

	const data = shipmentOrderContext.shipmentOrder as FTLShipment;

	const openBolFromUrl = async () => {
		await downloadFtlDefaultBOLFile(
			{ shipmentId: data.id, fileName: data.default_bol_file_name },
			{
				preview: false,
			},
		);

		const url = new URL(window.location.href);
		url.searchParams.delete('openBol');
		window.history.replaceState({}, document.title, url.toString());
	};

	useEffect(() => {
		openBol && openBolFromUrl();
	}, [openBol]);

	return (
		<div className='box-border grow-0 overflow-hidden  w-[382px] h-fit border-[2px] border-solid border-grey-05 rounded-[8px]'>
			<div className='w-full h-[180px]'>
				<MapBox
					start={data.pickup_city}
					to={data.destination_city}
					components={{ distance: true }}
				/>
			</div>

			<div className='box-border p-[24px] w-full h-fit'>
				<div className='flex items-center gap-[4px] h4-b text-[#333333]'>
					<div>{`${data.pickup_city.full_name.split(',').slice(0, 2).join(',')}`}</div>
					<div>
						<ArrowRightOutlined />
					</div>
					<div>{`${data.destination_city.full_name.split(',').slice(0, 2).join(',')}`}</div>
				</div>
				<Gap height='4px' />
				<div className='flex items-center gap-[4px] body-4-r text-grey-01'>
					{t(`Pickup Date`)}
					<Gap width='2px' />
					{formatMMDDYYYYDate(data.actual_pickup_date ?? data.estimated_pickup_date)}
				</div>

				<Gap height='16px' />

				<div className='flex'>
					<div className='w-1 grow'>
						<Item
							title={t(`Estimated Delivery`)}
							content={formatMMDDYYYYDate(data.estimated_delivery_date)}
						/>
					</div>
					<div className='w-1 grow'>
						<Item
							title={t(`Transit Time`)}
							content={`${data.quote_transit_days ?? '--'} ${t('days')}`}
						/>
					</div>
				</div>

				<Divider />

				<div className='flex h3-b text-black'>
					<div className='w-1 grow'>
						<div>{t('Total')}</div>
					</div>
					<div className='w-1 grow'>
						<div>{data.sell_rate ? `US＄${data.sell_rate}` : ''}</div>
					</div>
				</div>

				<Gap height='12px' />

				<div className='flex'>
					<div className='w-1 grow'>
						<Item title={t(`Base price`)} content={data.sell_rate} />
					</div>
					<div className='w-1 grow'>
						<Item title={t(`Extra insurance`)} content={`---`} />
					</div>
				</div>

				{/* <Divider />

				<div className='grid grid-cols-[auto_auto] grid-rows-[auto_auto] gap-x-[36px] gap-y-[16px]'>
					{data.vendor_documents.map((doc) => (
						<Item
							title={t(doc.type)}
							content={
								<div
									className='flex items-center gap-[8px] cursor-pointer'
									onClick={() =>
										downloadFtlFile(
											{ documentId: doc.id, shipmentId: data.id, fileName: doc.name },
											{ preview: true },
										)
									}
								>
									<span>{doc.name}</span>
									<CloudDownloadOutlined style={{ fontSize: '18px' }} />
								</div>
							}
						/>
					))}
					<Item
						title={t(`BOL(default)`)}
						content={
							<div
								className='flex items-center gap-[8px] cursor-pointer'
								onClick={() =>
									downloadFtlDefaultBOLFile(
										{ shipmentId: data.id, fileName: data.default_bol_file_name },
										{
											preview: true,
										},
									)
								}
							>
								<span>{data.default_bol_file_name}</span>
								<CloudDownloadOutlined style={{ fontSize: '18px' }} />
							</div>
						}
					/>
				</div> */}
			</div>
		</div>
	);
};
