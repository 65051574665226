import dayjs, { Dayjs } from 'dayjs';

export const isTimeBeforeLa = (time?: Dayjs) => {
	// 获取当前时间并转换为洛杉矶时间（America/Los_Angeles）
	const nowInLA = (time ?? dayjs()).tz('America/Los_Angeles');

	// 判断是否在12:00之前
	const isBeforeNoon = nowInLA.isBefore(
		dayjs().tz('America/Los_Angeles').set('hour', 12).set('minute', 0).set('second', 0),
	);

	return isBeforeNoon;
};
