import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Space, Table, Tag } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import * as _ from 'lodash';
import { AgentUser } from '@/interfaces/agent-user.interface';
import { User } from '@/interfaces/user.interface';
import { updateUser } from '@/services/apis/update-agent-user';
import { useMemoizedFn } from 'ahooks';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import Panel from '@/components/panel';
import { apiHooks } from '@/hooks/apis';
import { STATUS_DISABLED, STATUS_ENABLED, STATUS_VERIFYING } from './data';
import { TIER_MAP } from './use-company-modal/data';
import useLtlOrdersParams from './use-ltl-orders-params';
import useUserModal from './use-user-modal';

const Index = observer(() => {
	const { t } = useTranslation();

	const { parsedSearchParams, changeSearchParams } = useLtlOrdersParams();

	const {
		runAsync: getUserList,
		data: users,
		loading: loading,
		pagination,
		refresh,
	} = apiHooks.useGetAgentUsers();

	const { current, total, totalPage, changeCurrent, changePageSize } = pagination;

	useEffect(() => {
		getUserList(
			{
				current: parsedSearchParams.page ?? 1,
				pageSize: parsedSearchParams.per_page ?? 20,
			},
			{
				params: {},
			},
		);
	}, [current, getUserList]);

	const handleChangeUserStatus = async (record: AgentUser) => {
		await updateUser({
			data: {
				id: record.id,
				status: record.status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED,
			},
		});
		refresh();
	};

	const columns: ColumnsType<User> = [
		{
			title: t('ID'),
			dataIndex: 'id',
			width: '130px',
		},
		{
			title: t('User'),
			key: 'user',
			dataIndex: ['name'],
			width: '80px',
		},
		{
			title: t('Email'),
			dataIndex: 'email',
			width: '80px',
		},
		{
			title: t('Company'),
			dataIndex: ['company', 'name'],
			width: '80px',
		},
		{
			title: t('Bill to'),
			dataIndex: ['bill_to', 'name'],
			width: '80px',
		},
		{
			title: t('Tier'),
			dataIndex: ['company', 'tier_rate'],
			key: 'tier_rate',
			width: 100,
			render: (value) => {
				return <>{TIER_MAP[value as unknown as keyof typeof TIER_MAP]}</>;
			},
		},
		{
			title: 'LTL ' + t(' Margin') + ' %',
			dataIndex: ['company', 'tl_margin_percent'],
			key: 'tl_margin_percent',
			width: 100,
			render: (value) => {
				return <>{value}%</>;
			},
		},
		{
			title: 'FTL' + t('Margin') + ' %',
			dataIndex: ['company', 'ftl_margin_percent'],
			key: 'ftl_margin_percent',
			width: 100,
			render: (value) => {
				return <>{value}%</>;
			},
		},
		{
			title: t('Status'),
			width: '80px',
			render: (record) => {
				return (
					<Tag color={record.status === STATUS_ENABLED ? 'green' : 'red'}>{record.status_name}</Tag>
				);
			},
		},
		{
			title: t('Verified At'),
			dataIndex: 'verified_at',
			width: '80px',
			render(data) {
				return (
					<Tag color={data ? 'green' : 'red'}>
						{data ? dayjs(data).format('MM/DD/YYYY') : 'N/A'}
					</Tag>
				);
			},
		},
		{
			title: t('Action'),
			key: 'action',
			width: '80px',
			align: 'center',
			render: (record) => {
				return (
					<Space direction='horizontal'>
						{
							<Button
								disabled={!record.verified_at && record.status === STATUS_VERIFYING}
								type='link'
								onClick={() => {
									handleChangeUserStatus(record);
								}}
							>
								{record.status === STATUS_ENABLED ? t('Disable') : t('Enable')}
							</Button>
						}
						<Button
							type='link'
							onClick={() => {
								openUserModal(record);
							}}
						>
							{t('Edit')}
						</Button>
					</Space>
				);
			},
		},
	];

	const handleChange: TableProps<User>['onChange'] = useMemoizedFn((pagination) => {
		const { current, pageSize } = pagination;

		if (current !== parsedSearchParams.page) {
			changeCurrent(current);
		}
		if (pageSize !== parsedSearchParams.per_page) {
			changePageSize(pageSize);
		}

		if (current !== parsedSearchParams.page || pageSize !== parsedSearchParams.per_page) {
			const values = {
				...parsedSearchParams,
				page: current,
				per_page: pageSize,
			};

			changeSearchParams(values);
		}
	});

	const { openUserModal, closeUserModal, UserModal } = useUserModal();

	return (
		<div className=' box-border p-[51px_40px_0_40px] h-screen flex flex-col gap-[12px]'>
			<div className='flex justify-between items-center'>
				<div className='h3-b'>{t('Users')}</div>
				<Button type='primary' onClick={() => openUserModal()}>
					{t('New User')}
				</Button>
			</div>

			<Panel classNames='h-[calc(100%_-_47px)]'>
				<div style={{ height: 'calc(100% - 99px)' }}>
					<Table
						columns={columns}
						dataSource={users}
						loading={loading}
						className='auto-h-full-table  custom-table'
						pagination={{
							position: ['bottomCenter'],
							total,
							current,
							pageSize: 20,
						}}
						rowClassName='cursor-pointer'
						onChange={handleChange}
					/>
				</div>
				<UserModal onAfterSave={() => refresh()} />
			</Panel>
		</div>
	);
});

export default Index;
