import { Table, TableProps } from '@/components/table';

export const singleColumn = [
	{
		title: '',
		dataIndex: 'text',
	},
];
export function SingleColumnTable({
	commonCellStyle,
	...props
}: Omit<TableProps<{ text: string }>, 'columns'>) {
	return (
		<Table
			{...props}
			columns={singleColumn}
			className='border-[1px] border-solid border-grey-05 rounded-[8px] overflow-hidden'
			commonHeaderClass='hidden'
			commonCellClass='px-[16px]'
			commonCellStyle={{
				borderBottom: '1px solid #E6E6E6',
			}}
			commonBodyClass='h-[60px] flex items-center body-4-r text-black'
		/>
	);
}
