import { useTranslation } from 'react-i18next';
import { Divider, Form, Input, InputNumber, Select } from 'antd';
import { packageingTypes } from '@/interfaces/quote.interface';
import { useFTLContext } from '@/services/contexts';
import { useMemoizedFn } from 'ahooks';
import { FormItem } from '@/pages/ltl-search-rate/form-item';
import { formItemWrapper } from '@/pages/ltl-search-rate/form-item-wrapper';

export const ItemForm = ({ disabled }: { disabled?: boolean }) => {
	const { t } = useTranslation();

	const ftlContext = useFTLContext();

	const turnIntoInt = useMemoizedFn(() => {
		ftlContext.shipmentFormValues = {
			...ftlContext.shipmentFormValues,
			item_units: ftlContext.shipmentFormValues.item_units
				? Number(Number(ftlContext.shipmentFormValues.item_units).toFixed(0))
				: ftlContext.shipmentFormValues.item_units,
			item_weight: ftlContext.shipmentFormValues.item_weight
				? Number(Number(ftlContext.shipmentFormValues.item_weight).toFixed(0))
				: ftlContext.shipmentFormValues.item_weight,
		};
	});

	// 自定义校验规则，确保数字大于0
	const validatePositiveNumber = useMemoizedFn((rule, value) => {
		if (!value || value <= 0) {
			return Promise.reject(t(`Can't be 0`));
		}
		return Promise.resolve();
	});

	return (
		<div className='pl-[12px] flex flex-col gap-[24px]'>
			{/* title */}
			<div>
				<Divider />
				<div className='h4-b text-[#333333]'>{t(`Item`)}</div>
			</div>

			<div className='flex flex-col gap-[24px]'>
				<div className='flex gap-[12px]'>
					<FormItem titleProps={{ content: t('Commodity Name'), required: true }}>
						<Form.Item name='item_description' rules={[{ required: true }]}>
							<Input
								disabled={disabled}
								maxLength={255}
								style={formItemWrapper.style}
								className={`${formItemWrapper.className} w-[354px] h-[42px]`}
							/>
						</Form.Item>
					</FormItem>

					<FormItem titleProps={{ content: t('Number of Units'), required: true }}>
						<Form.Item name='item_units' rules={[{ validator: validatePositiveNumber }]}>
							<InputNumber
								disabled={disabled}
								min={0}
								suffix={null}
								style={formItemWrapper.style}
								className={`${formItemWrapper.className} w-full h-[42px]`}
								onBlur={() => {
									turnIntoInt();
								}}
							/>
						</Form.Item>
					</FormItem>

					<FormItem titleProps={{ content: ` ` }}>
						<Form.Item name='item_package_type' className='w-[152px]'>
							<Select
								disabled={disabled}
								variant='borderless'
								options={packageingTypes.map((i) => ({ label: i, value: i }))}
								style={formItemWrapper.style}
								className={`${formItemWrapper.className} h-[42px]`}
							/>
						</Form.Item>
					</FormItem>
				</div>

				<div className='flex gap-[12px]'>
					<FormItem titleProps={{ content: t('Weight'), required: true }}>
						<Form.Item name='item_weight' rules={[{ validator: validatePositiveNumber }]}>
							<InputNumber
								disabled={disabled}
								min={0}
								style={formItemWrapper.style}
								className={`${formItemWrapper.className} w-full h-[42px]`}
								onBlur={() => {
									turnIntoInt();
								}}
							/>
						</Form.Item>
					</FormItem>

					<FormItem titleProps={{ content: ` ` }}>
						<Form.Item name='item_weight_unit' className='w-[152px]'>
							<Select
								disabled={disabled}
								variant='borderless'
								options={['lbs', 'kg'].map((i) => ({ label: i, value: i }))}
								style={formItemWrapper.style}
								className={`${formItemWrapper.className} h-[42px]`}
							/>
						</Form.Item>
					</FormItem>
				</div>
			</div>
		</div>
	);
};
