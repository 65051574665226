import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, Select, Space, Switch, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { t } from 'i18next';

type TContact = {
	email: string;
	emailable: boolean;
	request_rate: boolean;
	fax: string;
	id?: number;
	name: string;
	phone: string;
	remark: string;
	role: string;
	type: Array<string>;
	cell: string;
};
export const CONTACT_TYPE_APT = 'APT';
export const CONTACT_TYPE_SALES = 'SALES';
export const CONTACT_TYPE_DISPATCH = 'DISPATCH';
export const CONTACT_TYPE_ACCOUNTING = 'ACCOUNTING';
export const CONTACT_TYPES = [
	CONTACT_TYPE_APT,
	CONTACT_TYPE_SALES,
	CONTACT_TYPE_DISPATCH,
	CONTACT_TYPE_ACCOUNTING,
];

export const ContactList: React.FC<{
	data: TContact[];
	showRequestRate?: boolean;
	updateData: (a: TContact[]) => void;
}> = ({ data = [], showRequestRate = false, updateData }) => {
	const handleAddList = () => {
		updateData([
			...data,
			{
				email: '',
				emailable: false,
				request_rate: false,
				fax: '',
				name: '',
				phone: '',
				remark: '',
				role: '',
				type: [],
				cell: '',
			},
		]);
	};

	const handleSelectCode = (index: number, value: string[]) => {
		const copy = [...data];
		copy[index].type = value;
		updateData(copy);
	};

	const handleRemove = (index: number) => {
		const copy = [...data];
		copy.splice(index, 1);
		updateData(copy);
	};

	const columns: ColumnsType<TContact> = [
		{
			title: t('ID'),
			key: 'id',
			width: 100,
			render: (text: any, record: TContact, dataIndex: number) => {
				return <>{record.id}</>;
			},
		},
		{
			title: t('Type'),
			key: 'type',
			width: 180,
			render: (text: any, record: TContact, dataIndex: number) => {
				return (
					<>
						<Select
							className='w-full'
							maxTagCount={'responsive'}
							value={record.type || []}
							mode='multiple'
							onChange={(code) => {
								handleSelectCode(dataIndex, code);
							}}
						>
							{CONTACT_TYPES.map((c) => (
								<Select.Option key={c} value={c}>
									{c}
								</Select.Option>
							))}
						</Select>
					</>
				);
			},
		},
		{
			title: t('Name'),
			key: 'name',
			width: 200,
			render: (text: any, record: TContact, dataIndex: number) => {
				return (
					<>
						<Form.Item
							className='m-0'
							name={['contacts', dataIndex, 'name']}
							rules={[{ required: true }]}
						>
							<Input />
						</Form.Item>
					</>
				);
			},
		},
		{
			title: t('Role'),
			key: 'role',
			width: 200,
			render: (text: any, record: TContact, dataIndex: number) => {
				return (
					<>
						<Form.Item className='m-0' name={['contacts', dataIndex, 'role']}>
							<Input />
						</Form.Item>
					</>
				);
			},
		},
		{
			title: t('Phone'),
			key: 'phone',
			width: 200,
			render: (text: any, record: TContact, dataIndex: number) => {
				return (
					<>
						<Form.Item className='m-0' name={['contacts', dataIndex, 'phone']}>
							<Input />
						</Form.Item>
					</>
				);
			},
		},
		{
			title: t('Email'),
			key: 'email',
			width: 200,
			render: (text: any, record: TContact, dataIndex: number) => {
				return (
					<>
						<Form.Item className='m-0' name={['contacts', dataIndex, 'email']}>
							<Input />
						</Form.Item>
					</>
				);
			},
		},
		{
			title: t('Fax'),
			key: 'fax',
			width: 200,
			render: (text: any, record: TContact, dataIndex: number) => {
				return (
					<>
						<Form.Item className='m-0' name={['contacts', dataIndex, 'fax']}>
							<Input />
						</Form.Item>
					</>
				);
			},
		},

		{
			title: t('Remark'),
			key: 'remark',
			width: 200,
			render: (text: any, record: TContact, dataIndex: number) => {
				return (
					<>
						<Form.Item className='m-0' name={['contacts', dataIndex, 'remark']}>
							<Input />
						</Form.Item>
					</>
				);
			},
		},
		{
			title: t('Action'),
			key: 'action',
			width: 100,
			fixed: 'right',
			render: (text: any, record: TContact, dataIndex: number) => {
				return (
					<>
						<a onClick={() => handleRemove(dataIndex)}>delete</a>
					</>
				);
			},
		},
	];

	return (
		<div>
			<h3>
				{t('Contacts')} <Button onClick={handleAddList}>{t('Add')}</Button>
			</h3>
			<Table columns={columns} dataSource={data} pagination={false} scroll={{ x: '100%' }} />
		</div>
	);
};
