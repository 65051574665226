import { useLTLContext } from '@/services/contexts';
import { useBoolean, useMemoizedFn, useMount } from 'ahooks';
import dayjs from 'dayjs';
import { apiHooks } from '@/hooks/apis';
import { message } from '@/utils/message';
import { useGenerateAddress } from './use-generate-address';
import useLtlSearchRateParams from './use-ltl-search-rate-params';

export const useInitQuote = () => {
	const ltlContext = useLTLContext();

	const { parsedSearchParams } = useLtlSearchRateParams();

	const [loading, { setTrue: startLoading, setFalse: endLoading }] = useBoolean(true);

	const { runAsync: getQuote } = apiHooks.useGetQuote({ manual: true });

	const { generateAddress } = useGenerateAddress();

	const initQuote = useMemoizedFn(async () => {
		try {
			startLoading();

			const res = await getQuote({
				path: {
					id: parsedSearchParams.quote_id,
				},
			});

			const quote = res.data.data;

			const pickupAddress = await generateAddress({
				city_name: quote.pickup_city_name,
				state_name: quote.pickup_state_name,
				zipcode: quote.pickup_zipcode,
			});

			const destinationAddress = await generateAddress({
				city_name: quote.destination_city_name,
				state_name: quote.destination_state_name,
				zipcode: quote.destination_zipcode,
			});

			if (pickupAddress) {
				ltlContext.pickupAddress = pickupAddress;
			}
			if (destinationAddress) {
				ltlContext.destinationAddress = destinationAddress;
			}

			ltlContext.stepOneFormValues = {
				pickup_date:
					parsedSearchParams.type === 'copy'
						? undefined
						: quote.pickup_date
						? dayjs(quote.pickup_date)
						: undefined, // 提货日期
				items:
					quote.items.length > 0
						? quote.items.map((item) => ({ ...item, id: Math.random().toString() }))
						: [],
				pickup_accessorials: quote.pickup_accessorials, // 提货附加服务
				destination_accessorials: quote.destination_accessorials, // 目的地附加服务
				shipment_accessorials: quote.shipment_accessorials, // 运输附加服务
			};
			ltlContext.pickUpAddressType = quote.destination_address_type;
			ltlContext.destinationAddressType = quote.destination_address_type;
			ltlContext.calcItemFreightClass();

			if (parsedSearchParams.type === 'copy') {
				ltlContext.step = 1;
			} else {
				ltlContext.quote = quote;
				ltlContext.step = 2;
			}
		} catch (err) {
			message.error(err.data?.message || err.data?.error);
		} finally {
			endLoading();
		}
	});

	useMount(() => {
		if (!ltlContext.quote && parsedSearchParams.quote_id) {
			initQuote();
		} else {
			endLoading();
		}
	});

	return {
		loading,
	};
};
